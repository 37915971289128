import { useState } from "react";
import "../css/CareerCardSection.css";
import CareerCard from "./CareerCard";
// import axios from "axios";

export default function CareerCardSection() {
	const [jobData, setJobData] = useState([]);

	// useEffect(() => {
	// 	const fetchJobData = async () => {
	// 		try {
	// 			const response = await axios.get(
	// 				"http://127.0.0.1:8000/get_jobs"
	// 			);
	// 			setJobData(response.data);
	// 		} catch (error) {
	// 			console.error("Error fetching job data:", error);
	// 		}
	// 	};

	// 	fetchJobData();
	// }, []);

	return (
		<div style={{ backgroundColor: "#0071c70c", padding: "3rem 0rem" }}>
			<h2
				className="mb-4 mt-8 text-4xl tracking-tight font-extrabold text-center text-gray-900 light:text-white"
				style={{ color: "#035898", fontFamily: "poppins" }}>
				Careers
			</h2>

			{(jobData.length === 0) ? (
				<>
				<p
				className="pb-2 lg:mb-3 font-light text-center text-gray-500 light:text-gray-400 sm:text-xl"
				style={{ fontFamily: "DM sans" }}>
				{/* We do not have any open positions at the moment.<br/> */}
				Subscribe to our mailing list to stay updated.
			</p>
			<form action="#">
			<div class=" mt-10 items-center md:mx-auto mb-3 max-w-screen-sm flex space-y-0 mx-10">
                  <div class="relative w-full">
                      <label for="email" class="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email address</label>
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none border border-black">
                          <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                      </div>
                      <input class="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300  rounded-l-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter your email" type="email" id="email" required="" style={{border: '1px #d1d5db solid'}}/>
                  </div>
                  <div>
                      <button type="submit" class="py-3 px-5 w-full text-sm font-medium text-center text-white border cursor-pointer bg-[#034f89] border-gray-600 rounded-none rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Subscribe</button>
                  </div>
              </div>
          </form>
				</>
			) : (
				<>			<p
				className="pb-2 lg:mb-3 font-light text-center text-gray-500 light:text-gray-400 sm:text-xl"
				style={{ fontFamily: "DM sans" }}>
				We are currently hiring!
			</p>

			<div className="cards-container">
				{jobData.map((job, index) => (
					<CareerCard data={job} key={index} />
				))}
			</div></>
			)
			}

		</div>
	);
}
