import styles from "../css/ContactUs.module.css";
import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';

function ContactUs() {
    const form = useRef();
    const captchaRef = useRef(null);
    const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const api_key = process.env.REACT_APP_EMAILJS_API_KEY;
    const captcha_sitekey = process.env.REACT_APP_CAPTCHA_SITEKEY_SECRET;
    const [ref, inView] = useInView({
        triggerOnce: true, // Only trigger once when entering the viewport
        threshold: 0.2, // Adjust the threshold as needed
      });
    const sendEmail = (e) => {
        e.preventDefault();
      
        // Get the captcha value
        const captchaValue = captchaRef.current.getValue();

        if (captchaValue.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops, something went wrong',
                text: 'Please verify that you are a human!',
                });
                return;
        }
      
        // Convert form data to JSON
        const formData = new FormData(form.current);
        const formDataJson = {};
        for (let [key, value] of formData.entries()) {
          formDataJson[key] = value;
        }
      
        // Add the captcha value to the JSON object
        formDataJson['g-recaptcha-response'] = captchaValue;
      
        // Send the email using emailjs
        emailjs
          .send(service_id, 'template_5w049co', formDataJson, api_key)
          .then((result) => {
            Swal.fire({
              icon: 'success',
              title: 'Message Sent Successfully',
            });
            // Reset the form and captcha
            form.current.reset();
            captchaRef.current.reset();
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops, something went wrong',
              text: (error.text.includes('g-recaptcha-response'))? 'Please complete the captcha!' : error.text,
            });
          })
      };
      
    return (
        <div ref={ref} style={{ 'padding': '50px 0px', 'background-color': '#0071c70c' }} id='contact'>
            <h2 className="mb-4 mt-4 text-4xl tracking-tight font-extrabold text-center text-gray-500 light:text-white" style={{ 'color': '#035898', 'fontFamily':'poppins', fontWeight: '600',marginLeft:'2rem', marginRight:'2rem' }}>Request a quote</h2>
            <p className="mb-2 p-2 lg:mb-3 font-light text-center text-gray-500 light:text-gray-400 sm:text-xl" style={{'fontFamily':'DM sans',marginLeft:'2rem', marginRight:'2rem' }}>Share a brief about your project and get a guaranteed response within 24 hours.</p>
            <div className={styles.wrapper}>
                <div className={styles.imageContact}>

                </div>
                <div className={styles.contactus}>
                    <section className="light:bg-gray-900" >
                        <div className={`py-8 lg:py-16 px-4 mx-auto max-w-screen-md`} >
                            <form ref={form} onSubmit={sendEmail} className={`space-y-4`}>
                                <div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 ">
                                    <div className={`${(inView)?(styles['hello']):("")}`}>
                                        <label for="name" className="block mb-2 text-sm font-medium text-gray-500 light:text-gray-300">Your Name</label>
                                        <input type="name" name="user_name" id="name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light" placeholder="Your name" required></input>
                                    </div>
                                    <div className={`${(inView)?(styles['hello']):("")}`} style={{'animation-delay':'0.3s'}}>
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-500 light:text-gray-300">Your Email</label>
                                        <input type="email" name="user_email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light" placeholder="name@email.com" required></input>
                                    </div>
                                </div>
                                <div className={`${(inView)?(styles['hello']):("")} grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-8`} style={{'animation-delay':'0.6s'}}>
                                    <div>
                                        <label for="phone" class="block mb-2 text-sm font-medium text-gray-500 light:text-white">Phone Number</label>
                                        <input type="tel" name="phone" id="phone" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light" placeholder="telephone" minLength={10} required></input>
                                    </div>
                                    <div className={`${(inView)?(styles['hello']):("")}`} style={{'animation-delay':'0.9s'}}>
                                        <label for="service" class="block mb-2 text-sm font-medium text-gray-500 light:text-gray-300">Select Service</label>
                                        <select id="service" name="service" defaultValue={'Select service'} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light">
                                            <option class="text-gray-500 light:text-gray-300" disabled>Select service</option>
                                            <option>Web Development</option>
                                            <option>Mobile App Development</option>
                                            <option>Game Development</option>
                                            <option>UI/UX Design</option>
                                            <option>SEO Services</option>
                                            <option>Digital Marketing</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={`${(inView)?(styles['hello']):("")} sm:col-span-2`} style={{'animation-delay':'1.2s'}}>
                                    <label for="message" className="block mb-2 text-sm font-medium text-gray-500 light:text-gray-400">Your Message</label>
                                    <textarea id="message" name='message' rows="6" className="block p-2.5 w-full text-sm text-gray-500 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
                                </div>
                                <div className={`${(inView)?(styles['hello']):("")}`} style={{'animation-delay':'1.5s'}}>
                                    <ReCAPTCHA
                                        sitekey={captcha_sitekey}
                                        ref={captchaRef}
                                        />
                                </div>
                                <div className={`${(inView)?(styles['hello']):("")} sm:col-span-2`} style={
                                    {
                                        'background-color': '#035898',
                                        'height': '40px',
                                        'width': '160px',
                                        'border-radius': '5px',
                                        'border': '1px solid',
                                        'borderColor': 'none',
                                        'line-height': '35px',
                                        'text-align': 'center',
                                        'color': '#fff',
                                        fontFamily: 'poppins',
                                        fontSize: '13px',
                                        'animation-delay':'1.8s'
                                    }
                                }>
                                    
                                    <button type="submit" style={{ width: '160px' }}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;