import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/CareerCard.css";
import { FaMoneyBillAlt } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { MdPeople } from "react-icons/md";
import { TbPointFilled } from "react-icons/tb";
import { MdArrowOutward } from "react-icons/md";

export default function CareerCard(props) {
	const [expanded, setExpanded] = useState(false);

	const toggleJobDescription = () => {
		setExpanded(!expanded);
	};

	const renderJobDescriptionPoints = () => {
		const requirements = props.data.requirements;
		if (expanded) {
			return requirements.map((description, index) => (
				<div className="point" key={index}>
					<TbPointFilled className="point-icon" />
					<p>{description}</p>
				</div>
			));
		} else {
			return requirements.slice(0, 3).map((description, index) => (
				<div className="point" key={index}>
					<TbPointFilled className="point-icon" />
					<p>{description}</p>
				</div>
			));
		}
	};

	return (
		<div className="career-card" style={{ backgroundColor: "white" }}>
			<div className="card-up" style={{ cursor: "pointer" }}>
				<div className="card-left">
					<div className="new">new</div>
					<h3 className="title">
						{props.data.title.replace("_", " ")}
					</h3>
					<p>{props.data.location}</p>
					<div className="career-details-icons">
						<div className="salary">
							<FaMoneyBillAlt size={19} color="white" />
							<p>{props.data.salary}</p>
						</div>
						<div className="job-type">
							<FaBriefcase size={14} color="white" />
							<p>{props.data.jobType}</p>
						</div>
						<div className="candidates">
							<MdPeople size={19} color="white" />
							<p>{props.data.workArrangement}</p>
						</div>
					</div>
				</div>
				<Link
					to={"/career/apply/" + props.data.id}
					className="apply-button">
					Apply
					<MdArrowOutward color="white" size={16} />
				</Link>
			</div>

			<div className={`job-description ${expanded ? "expanded" : ""}`}>
				<p>Apply if you have:</p>
				<div className="points">{renderJobDescriptionPoints()}</div>
				{expanded ? (
					<button className="read-more" onClick={toggleJobDescription}>
						Read less
					</button>
				) : (
					<button className="read-more" onClick={toggleJobDescription}>
						Read more ...
					</button>
				)}
			</div>
		</div>
	);
}
