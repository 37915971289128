import JobApplication from "../components/JobApplication";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Layout from "./Layout";

function ApplicationPage() {
	const routeParams = useParams();
	const jobId = routeParams.jobId;
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [job, setJob] = useState({});

	useEffect(() => {
		// const fetchJobData = async () => {
		// 	try {
		// 		const response = await axios.get(
		// 			`http://localhost:8000/get_job/${jobId}`
		// 		);
		// 		setJob(response.data);
		// 		setLoading(false);
		// 	} catch (error) {
		// 		navigate("/404");
		// 	}
		// };

		// fetchJobData();
		navigate("/404");
	}, []);

	if (loading) {
		return <div></div>;
	} else {
		return (
			<Layout>
				<JobApplication data={job}/>
			</Layout>
		);
	}
}

export default ApplicationPage;
