import styles from "../css/card.module.css";
import React from "react";
import { useInView } from "react-intersection-observer";
import CusromeBt from "./CustomButton";
import mobile from "images/mobile-dev.svg";
import UI from "images/UI-2.svg";
import gaming from "images/gaming.svg";
import SEO from "images/SEO.svg";
import DG from "images/DG2.svg";

const cardData = [
  {
    id: 1,
    image: "url('../../static/images/web.svg')",
    cardHeight: "29rem",
    header: "Web Development",
    description:
      "Empower your business with expertly crafted websites that drive growth and captivate audiences.",
  },
  {
    id: 2,
    image: mobile,
    cardHeight: "29rem",
    header: "Mobile App Development",
    description:
      "Elevate your business with captivating mobile apps that drive growth and engage users.",
  },
  {
    id: 3,
    image: UI,
    cardHeight: "29rem",
    header: "UI/UX Design",
    description:
      "Create immersive digital experiences that captivate and delight users with our expertly crafted UI/UX designs.",
  },
  {
    id: 4,
    image: gaming,
    cardHeight: "29rem",
    header: "Game Development",
    description:
      "Experience the thrill of unforgettable gameplay with our exceptional game development prowess.",
  },
  {
    id: 5,
    image: SEO,
    cardHeight: "29rem",
    header: "SEO",
    description:
      "Power your business with tailored software solutions that drive efficiency and innovation.",
  },
  {
    id: 6,
    image: DG,
    cardHeight: "29rem",
    header: "Digital Marketing",
    description:
      "Elevate your brand's online presence with our comprehensive digital marketing strategies.",
  },
];

function ServiceCard(props) {
  return (
    <div
      className={styles.main}
      style={{
        "max-height": props.data.cardHeight ? props.data.cardHeight : "29rem",
        padding: "30px 0px 0px",
      }}
    >
      <div
        className={`${styles["image" + props.data.id]} ${styles["image"]}`}
        styles={{ "background-image": props.data.image }}
      ></div>
      <div className={styles.content}>
        <div className={styles.header}>
          <p>
            {props.data.header
              ? props.data.header
              : "This is the main heading. Change it by passing header in props.data."}
          </p>
        </div>
        <div className={styles.contentInfo}>
          <p>
            {props.data.description
              ? props.data.description
              : 'This is description. Change it by passing description="your description" in props.data.'}
          </p>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <CusromeBt
            content="Get started"
            width="100%"
            bg="#035898"
            link="#contact"
          />
        </div>
      </div>
    </div>
  );
}

function ServiceCards() {
  const [ref, inView] = useInView({ triggerOnce:true,threshold: 0 });
  return (
    <>
      <h2
        id="services"
        className="mb-4 text-4xl tracking-tight font-bold text-center text-gray-900 light:text-white"
        style={{ color: "#035898", paddingTop: "6rem", fontFamily: "poppins", fontWeight: "600" }}
      >
        Services
      </h2>
      <p
        className="mb-1 lg:mb-3 font-light text-center text-gray-500 light:text-gray-400 sm:text-xl"
        style={{ fontFamily: "DM Sans" }}
      >
        Let us know what you want.
      </p>
      <div ref={ref} className={styles.wrapper}>
        {cardData.map((data) => (
          <div className={`${(inView)?(styles['inner']):('')}`}>
            <ServiceCard data={data} />
          </div>
        ))}
      </div>
    </>
  );
}

export default ServiceCards;
