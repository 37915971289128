import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./homepage";
import Errorr from "../components/error";
import ApplicationPage from "./applicationPage";
import CareerPage from "./careerPage";


export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />}/>
        <Route exact path="/career" element={<CareerPage />}/>
        <Route path="/career/apply/:jobId" element={<ApplicationPage />}/>
        <Route path="*" element={<Errorr />}/>
      </Routes>
    </BrowserRouter>
  );
}