import React from "react";
import { useInView } from "react-intersection-observer";
import Danish from "images/Danish.jpg";
import styles from "../css/testimonials.module.css";

function Testimonials() {
  const testimonialData = [
    {
      name: "Muhammad Danish",
      position: "Software Design Engineer at Xflow",
      heading: "I loved their services!",
      quote:
        "If you care for your time, I hands down would go with this. Asteramind's team was very professional and met all the deadlines on time. Definitely recommend!",
      image: Danish,
    },
    {
      name: "Fatima Shahid",
      position: "Web Developer at NUST",
      heading: "Best decision we made for our business!",
      quote:
        "Choosing Asteramind for our software development needs was one of the best decisions we made. Their commitment to excellence and attention to detail resulted in a flawless software product that has greatly benefited our business. ",
      image:
        "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png",
    },
    {
      name: "Ali Raza",
      position: "Flutter Developer at Assessmate.io",
      heading: "Their services are top-notch! Loved working with them!",
      quote:
        "Asteramind delivered a robust and intuitive software solution that exceeded our expectations. Would definitely like to work with them in the future.",
      image:
        "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png",
    },
    {
      name: "Muhammad Mustafa",
      position: "Freelancer at Fiverr",
      heading: "Asteramind helped me start my business!",
      quote:
        "Asteramind played a pivotal role in helping me launch my business. Their expert guidance and exceptional software solutions provided the foundation for my success.",
      image:
        "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/joseph-mcfall.png",
    },
  ];
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="testimonials" id="testimonals">
      <h2
        className="mb-8 mt-0 text-4xl pt-24 tracking-tight font-bold text-center text-gray-900 light:text-white"
        style={{ color: "#035898", 'fontFamily':'poppins', fontWeight: '600' }}
        ref={ref}
      >
        Testimonials
      </h2>
      <p className="mb-20 lg:mb-12 font-light text-center text-gray-500 light:text-gray-400 sm:text-xl" style={{'fontFamily':'DM sans', 'fontWeight':'lighter'}}>
        Hear what our clients have to say.
      </p>
      <div className="grid mb-8 border border-gray-200 rounded-lg shadow-sm light:border-gray-700 md:mb-12 md:grid-cols-2">
        {testimonialData.map((testimonial) => (
          <figure
            className={`${(inView)?(styles['testimonial-animation']):('')} border-solid flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-100`}
          >
            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 light:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 light:text-white">
                {testimonial.heading}
              </h3>
              <p className="my-4">{testimonial.quote}</p>
            </blockquote>
            <figcaption className="flex items-center justify-center space-x-3">
              <img
                className="rounded-full w-9 h-9"
                src={testimonial.image}
                alt="profile"
              />
              <div className="space-y-0.5 font-medium light:text-white text-left">
                <div>{testimonial.name}</div>
                <div className="text-sm text-gray-500 light:text-gray-400">
                  {testimonial.position}
                </div>
              </div>
            </figcaption>
          </figure>
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
