import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import "../css/styles.css";

const FAQ = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
};

export default function FAQS() {
  const [ref, inView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [faqs, setFaqs] = useState([
    {
      question: "What are the different services that Asteramind offers?",
      answer:
        "We offer a wide range of software development services including: Web Development, Mobile App Development, UI/UX Design, Game Development, and Digital Marketing.",
      open: false
    },
    {
      question: "What technologies or programming languages does your team have expertise in?",
      answer: "We provide software solutions using the following technologies: JavaScript, NodeJS, Python, Php, Flutter, C, C++, SQL as well as other famous fullstack development tech-stacks.",
      open: false
    },
    {
      question:
      "What industries or sectors does Asteramind specialize in?",
      answer: "We specialize in web and mobile app development, serving a wide range of industries and sectors with innovative software solutions.",
      open: false
    },
    {
      question:
        "Can you assist with ongoing maintenance and support after the software is developed?",
      answer: "Yes, we provide comprehensive ongoing maintenance and support services even after the software has been developed!",
      open: false
    },
    {
      question:
        "How do you determine project timelines and costs?",
      answer: "We determine project timelines and costs through a comprehensive analysis of requirements, scope, and deliverables. By collaborating closely with our clients, we provide accurate estimates aligned with their expectations for successful project execution.",
      open: false
    }
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="master">
      <h1 className="text-4xl" style={{ textAlign: "center", padding: "3rem 2rem 1rem 2rem", color: "#035898", 'fontFamily':'poppins', fontWeight: '600'  }}>Faqs</h1>
      <p className="mb-4 lg:mb-8 font-light text-center text-gray-500 light:text-gray-400 sm:text-xl" style={{'fontFamily':'DM Sans'}}>
        Frequently asked questions
      </p>
      <div ref={ref} className="faqwrapper">
        <div className="faqImage"></div>
        <div className={`faqs`}>
          {faqs.map((faq, index) => (
            <div className={`${(inView)?("faq-animation"):("opacity-0")}`}>
              <FAQ faq={faq} index={index} toggleFAQ={toggleFAQ} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
