import CareerLanding from "../components/CareerLanding";
import CareerCardSection from "../components/CareerCardSection";
import Layout from "./Layout";

function CareerPage() {
  return (
    <Layout>
      <CareerLanding></CareerLanding>
      <CareerCardSection></CareerCardSection>
    </Layout>
  );
}

export default CareerPage;
