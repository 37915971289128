import "./App.css";
import Router from "./pages/Router";

function App() {
	return (
		<div className="App">
			<Router></Router>
		</div>
	)
}

export default App;
