import styles from "../css/Footer.module.css";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdPlace, MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles["footer-top"]}>
        <div className={styles["footer-left"]}>
          <h1>Asteramind</h1>
          <div className={styles["footer-company-about"]}>
            <h5>About the company</h5>
            <p>
              Asteramind is recognized as a best Software house in Islamabad that
              offers multiple IT services throughout Pakistan. Along with software
              development, they also provide digital marketing, SEO, Social media
              Marketing, and all branding services.
            </p>

          </div>

          <div className={styles["footer-icons"]}>
            <a href="https://www.facebook.com/profile.php?id=100093343599834&mibextid=ZbWKwL">
              <div className={styles["icon-wrapper"]}>
                <FaFacebook size={22} />
              </div>
            </a>
            <a href="https://www.instagram.com/asteramindofficial/">
              <div className={styles["icon-wrapper"]}>
                <FaInstagram size={22} />
              </div>
            </a>
            <a href="https://www.linkedin.com/company/asteramind/">
              <div className={styles["icon-wrapper"]}>
                <FaLinkedin size={22} />
              </div>
            </a>            
            <a href="https://twitter.com/asteramind">
              <div className={styles["icon-wrapper"]}>
                <FaTwitter size={22} />
              </div>
            </a>
            <a href="https://github.com/Asteramind">
              <div className={styles["icon-wrapper"]}>
                <FaGithub size={22} />
              </div>
            </a>

          </div>
        </div>

        <div className={styles["footer-container"]}>
          <div className={styles["footer-middle"]}>
            <div className={styles["footer-nav-links"]}>
              <h3>Company</h3>
              <Link to="/">Home</Link>
              <a href="/#services">Services</a>
              <a href="/#faq">FAQs</a>
              <a href="/#about">About</a>
              <a href="/#contact">Contact Us</a>
              <a href="/career">Careers</a>
            </div>
          </div>

          <div className={styles["footer-right"]}>
            <h2>Find Us</h2>
            <div className={styles["footer-element"]}>
              <div>
                <div className={styles["icon-wrapper-location"]}>
                  <MdPlace size={28} color="#003A66" />
                </div>
              </div>
              <div className={styles["location-content"]}>
                <a className="hover:text-[#035898]" href='https://goo.gl/maps/oQ3whqMttM2pLLoE7'>NUST H-12 <br/> Islamabad, Pakistan</a>
              </div>
            </div>
            <div className={styles["footer-element"]}>
              <div className={styles["icon-wrapper-mail"]}>
                <MdEmail size={23} color="#003A66" />
              </div>
              <a className="hover:text-[#035898]" href="mailto:info@asteramind.com">info@asteramind.com</a>
            </div>
            <div className={styles["footer-element"]}>
              <div className={styles["icon-wrapper-phone"]}>
                <FaPhoneAlt size={21} color="#003A66" />
              </div>
              <a className="hover:text-[#035898]" href="tel:923098583880">+92 (309) 8583 880</a>
            </div>


          </div>
        </div>
      </div>
      <div className={styles["footer-bottom"]}>Copyright @ Asteramind {new Date().getFullYear()}</div>
    </div>
  );
}
