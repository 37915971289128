import React, { useEffect, useRef, useState } from "react";

function StarryNight(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const [x, setx] = useState(200);
  const [linedistance, setlinedistance] = useState(120);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);

    if (width < 1247) {
      setx(100);
      setlinedistance(100);
    } else {
      setx(200);
      setlinedistance(150);
    }
  };
  const canvasRef = useRef(null);
  const mouse = {
    x: 0,
    y: 0,
  };
  const stars = [];
  const FPS = 20;

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = width;
    canvas.height = window.innerHeight;

    // Push stars to array
    for (let i = 0; i < x / 2; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 1 + 0.5,
        // vx: Math.floor(Math.random() * 50) - 25,
        // vy: Math.floor(Math.random() * 50) - 25,
        vx: Math.floor(Math.random() * 20) - 10,
        vy: Math.floor(Math.random() * 20) - 10,
      });

    }
    for (let i = 0; i < x; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,

        // vx: Math.floor(Math.random() * 50) - 25,
        // vy: Math.floor(Math.random() * 50) - 25,
        vx: Math.floor(Math.random() * 20) - 10,
        vy: Math.floor(Math.random() * 20) - 10,
      });
    }

    canvas.addEventListener("mousemove", function (e) {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    });

    function distance(point1, point2) {
      let xs = 0;
      let ys = 0;

      xs = point2.x - point1.x;
      xs = xs * xs;

      ys = point2.y - point1.y;
      ys = ys * ys;

      return Math.sqrt(xs + ys);
    }

    function update() {
      for (let i = 0, x = stars.length; i < x; i++) {
        const s = stars[i];

        s.x += s.vx / FPS;
        s.y += s.vy / FPS;

        if (s.x < 0 || s.x > canvas.width) s.vx = -s.vx;
        if (s.y < 0 || s.y > canvas.height) s.vy = -s.vy;
      }
    }

    function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.globalCompositeOperation = "darker";

      for (let i = 0, x = stars.length; i < x; i++) {
        const s = stars[i];

        ctx.fillStyle = "#1C72B8";
        ctx.beginPath();
        ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.fillStyle = "black";
        ctx.stroke();
      }

      ctx.beginPath();
      for (let i = 0, x = stars.length; i < x; i++) {
        const starI = stars[i];
        ctx.moveTo(starI.x, starI.y);
        if (distance(mouse, starI) < linedistance) {
          for (let j = 0, x = stars.length; j < x; j++) {
            const starII = stars[j];
            if (distance(starI, starII) < 100) {
              //ctx.globalAlpha = (1 / 150 * distance(starI, starII).toFixed(1));
              ctx.lineTo(starII.x, starII.y);
            }
          }
        }
      }
      ctx.lineWidth = 0.08;
      ctx.strokeStyle = "#1C72B8";
      ctx.stroke();
    }

    function tick() {
      draw();
      update();
      requestAnimationFrame(tick);
    }

    tick();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [width, x, linedistance]);

  return <canvas ref={canvasRef} />;
}

export default StarryNight;
