export const links = [
  {
    id: 1,
    url: "/",
    text: "home",
  },
  {
    id: 2,
    url: "/#about",
    text: "about",
  },
  {
    id: 3,
    url: "/#services",
    text: "services",
  },
  {
    id: 4,
    url: "/#contact",
    text: "contact",
  },
  {
    id: 5,
    url: "/career",
    text: "careers",
  },
]