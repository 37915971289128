import styles from "../css/JobApplication.module.css";
import React, { useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

function JobApplication(props) {
	const form = useRef();
	const captchaRef = useRef(null);
	const captcha_sitekey = process.env.REACT_APP_CAPTCHA_SITEKEY_SECRET;

	function handleSubmit(event) {
		event.preventDefault();

		const firstname = event.target.elements.firstname.value;
		const lastname = event.target.elements.lastname.value;
		const email = event.target.elements.email.value;
		const phone = event.target.elements.phone.value;
		const resume = event.target.elements.resume.files[0];

		const formData = new FormData();

		formData.append("jobId", props.data.id);
		formData.append("firstName", firstname);
		formData.append("lastName", lastname);
		formData.append("email", email);
		formData.append("phoneNumber", phone);
		formData.append("resume", resume);
		formData.append("captcha", captchaRef.current.getValue());

		// Send the form data to the server using axios
		axios
			.post("http://localhost:8000/post_application/", formData)
			.then((response) => {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: response.data.message,
				});

				event.target.reset();
				captchaRef.current.reset();
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					title: "Failed",
					text: error.response.data.message,
				});
			});
	}

	return (
		<div className={styles.contact} id="contact">
			<div className={styles.info}>
				<h2
					className="mb-8 mt-0 text-4xl pt-24 tracking-tight font-bold text-center text-gray-900 light:text-white"
					style={{
						color: "#035898",
						fontFamily: "poppins",
						fontWeight: "600",
					}}>
					Apply for {props.data.title.split("_").join(" ")}
				</h2>
				<p className="mb-1 lg:mb-3 font-light text-center text-gray-500 light:text-gray-400 sm:text-xl">
					{props.data.workArrangement} {props.data.jobType}
				</p>
				<div className={styles.wrapper} style={{ padding: "0px" }}>
					<div className={styles.imageContact}></div>
					<div className={styles.contactus}>
						<div className="py-4 lg:py-4 px-2 mx-auto max-w-screen-md">
							<div>
								<h6 className="mb-4 text-xl tracking-tight font-bold ml-4 text-gray-500 light:text-white">
									Requirements
								</h6>
								{props.data.requirements.map((requirement) => {
									return (
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "15px",
												color: "#6B7280",
												lineHeight: "1.5rem",
											}}
											className={styles["bullet-point"]}>
											<div
												className={
													styles["bullet-div"]
												}>
												-
											</div>
											<p>{requirement}</p>
										</div>
									);
								})}
								<h6 className="mt-4 mb-4 text-xl tracking-tight font-bold ml-4 text-gray-500 light:text-white">
									Responsibilities
								</h6>
								{props.data.responsibilities.map(
									(responsibility, index) => {
										return (
											<div
												style={{
													fontFamily: "poppins",
													fontSize: "15px",
													color: "#6B7280",
													lineHeight: "1.5rem",
												}}
												className={
													styles["bullet-point"]
												}>
												<div
													className={
														styles["bullet-div"]
													}>
													-
												</div>
												<p>{responsibility}</p>
											</div>
										);
									}
								)}
								<h6 className="mb-2 mt-4 text-xl tracking-tight font-bold ml-4 text-gray-500 light:text-white">
									Salary
								</h6>
								<div
									style={{
										fontFamily: "poppins",
										fontSize: "15px",
										color: "#6B7280",
										lineHeight: "1.5rem",
									}}
									className={styles["bullet-point"]}>
									<div className={styles["bullet-div"]}>
										-
									</div>
									<p>{props.data.salary}</p>
								</div>
							</div>
							<div className={styles.metaInfo}>
								<h6 className="mb-2 mt-4 text-xl tracking-tight font-bold ml-4 text-gray-500 light:text-white">
									Work Arrangement
								</h6>
								<div
									style={{
										fontFamily: "poppins",
										fontSize: "15px",
										color: "#6B7280",
										lineHeight: "1.5rem",
									}}
									className={styles["bullet-point"]}>
									<div className={styles["bullet-div"]}>
										-
									</div>
									<p>{props.data.workArrangement}</p>
								</div>
							</div>
							<div className={styles.metaInfo}>
								<h6 className="mb-2 mt-4 text-xl tracking-tight font-bold ml-4 text-gray-500 light:text-white">
									Job type
								</h6>
								<div
									style={{
										fontFamily: "poppins",
										fontSize: "15px",
										color: "#6B7280",
										lineHeight: "1.5rem",
									}}
									className={styles["bullet-point"]}>
									<div className={styles["bullet-div"]}>
										-
									</div>
									<p>{props.data.jobType}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ "background-color": "#0071c70c" }}>
				<div className={styles.wrapper}>
					<div className={styles.contactus}>
						<h2
							className="mb-4 pt-12 text-4xl tracking-tight font-extrabold text-center text-gray-900 light:text-white"
							style={{
								color: "#035898",
								fontFamily: "poppins",
								fontWeight: "600",
							}}>
							Application
						</h2>
						<section className="light:bg-gray-900">
							<div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
								<form
									ref={form}
									onSubmit={handleSubmit}
									className="space-y-8">
									<div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8">
										<div>
											<label
												for="firstname"
												className="block mb-2 text-sm font-medium text-gray-900 light:text-gray-300">
												Your First name
											</label>
											<input
												type="firstname"
												name="firstname"
												id="firstname"
												className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light"
												placeholder="First Name"
												required></input>
										</div>
										<div>
											<label
												for="lastname"
												className="block mb-2 text-sm font-medium text-gray-900 light:text-gray-300">
												Your Last name
											</label>
											<input
												type="lastname"
												name="lastname"
												id="lastname"
												className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light"
												placeholder="Last Name"
												required></input>
										</div>
									</div>
									<div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8">
										<div>
											<label
												for="email"
												className="block mb-2 text-sm font-medium text-gray-900 light:text-gray-300">
												Your email
											</label>
											<input
												type="email"
												name="user_email"
												id="email"
												className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light"
												placeholder="name@gmail.com"
												required></input>
										</div>
										<div>
											<label
												for="phone"
												class="block mb-2 text-sm font-medium text-gray-900 light:text-white">
												Phone number
											</label>
											<input
												type="tel"
												id="phone"
												class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light"
												placeholder="Phone"
												minLength={10}
												required></input>
										</div>
									</div>
									<div className="sm:col-span-2">
										<label
											class="block mb-2 text-sm font-medium text-gray-900 light:text-white"
											for="user_avatar">
											Upload file
										</label>
										<input
											class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 light:text-gray-400 focus:outline-none light:bg-gray-100 light:border-gray-600 light:placeholder-gray-400"
											accept="application/pdf"
											aria-describedby="resume"
											id="resume"
											type="file"
											required></input>
										<div
											class="mt-1 text-sm text-gray-500 light:text-gray-300"
											id="resume format instructions">
											Only pdf files are accepted
										</div>
									</div>
									<ReCAPTCHA
										sitekey={captcha_sitekey}
										ref={captchaRef}
									/>
									<div
										className="sm:col-span-2"
										style={{
											"background-color": "#035898",
											height: "40px",
											width: "160px",
											"border-radius": "5px",
											border: "1px solid",
											borderColor: "none",
											"line-height": "35px",
											"text-align": "center",
											color: "#fff",
											fontFamily: "poppins",
											fontSize: "13px",
										}}>
										<button
											type="submit"
											style={{ width: "160px" }}>
											Submit
										</button>
									</div>
								</form>
							</div>
						</section>
					</div>
					<div
						className={styles.imageContact}
						style={{
							"background-image":
								"url(/undraw_files_sent_re_kv00.svg)",
							backgroundSize: "700px",
						}}></div>
				</div>
			</div>
		</div>
	);
}

export default JobApplication;
