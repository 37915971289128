import Navbar from "../components/navbar";
import Footer from "../components/Footer";

function Layout({ children }) {
    return (
        <div>
            <Navbar></Navbar>
            {children}
            <Footer></Footer>
        </div>
    )
}

export default Layout;