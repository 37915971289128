import React from "react"
import { links } from "./data"
import styles from "../css/navbar.module.css"
import { GhostNavbar } from "react-hamburger-menus"
import "react-hamburger-menus/dist/style.css";
import CustomeBt from "./CustomButton";
import Logo from "images/finalx.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY

    if (currentScrollPos > prevScrollPos) {
      setVisible(false)
    } else {
      setVisible(true)
    }

    setPrevScrollPos(currentScrollPos)
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
  }


  useEffect(() => {
    handleResize();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  }, [prevScrollPos, width])

  const closeNav = () => {
    // close ghost navbar
    document.querySelector('.rhm-gn-navigation__checkbox').click();
  }

  return (
    <>
      <header className={`${styles.Header} sticky ${visible ? 'top-0' : ''}`}>
        <div className='logo'>
          {/* <img src={Logo} style={{width:"70px", height:'auto', margin: "5px "}} alt='logo' /> */}
          <img src={Logo} style={{ width: "200px", height: 'auto', margin: "10px 0px" }} alt='logo' />
        </div>
        <nav>
          <ul className={styles.list}>
            {links.map((link) => {
              const { id, url, text } = link
              return (
                <li key={id}>
                  {(url.includes('#'))?(
                    <a href={url}>{text}</a>
                  ):(
                       <Link to={url}>{text}</Link>
                  )}
               
                </li>
              )
            })}
          </ul>
        </nav>

        <div className={styles.socialIcons}>
          <CustomeBt bg='#035898' content='Contact Us' link='/#contact' onClick='true'></CustomeBt>
        </div>

        {visible && width<1136 && <div className={styles.small}>
          <GhostNavbar
            styles={{
              navigationBackground: {
                backgroundColor: "white"
              },
              fontFamily: "poppins",
              navigationButton: {
                borderRadius: "5px",
                width: "50px",
                backgroundColor: "rgba(255, 255, 255, 0.916)",
                boxShadow: 'none',
              },
            }}
            rightReveal
            backgroundBox
            fontColor="#003055"
            fontSize="1.2rem"
          >
            <ul style={{
              fontFamily: 'poppins'
            }}>
              {links.map((link) => {
                const { id, url, text } = link
                return (
                <li key={id}>
                    <a href={url} onClick={closeNav} style={{textTransform:'capitalize'}}>{text}</a>
                  </li>
                )
              })}

            </ul>
          </GhostNavbar>
        </div>
}
      </header>
    </>
  )
}

export default Navbar;