import styles from "../css/Mission.module.css";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import React from "react";
import { useInView } from "react-intersection-observer";

export default function Mission() {
  const [ref, inView] = useInView({ triggerOnce: true,threshold: 0.5 });
  const points = [
    "Asteramind is team of highly skilled individuals commited to leveraging their technological expertise to develop software solutions.",
    "Our aim is to empower individuals, organizations, and communities to overcome challeges, making the world a better place for everyone.",
    "We specialize in offering comprehensive software development services ranging from development to digital marketing.",
    "Leveraging cutting-edge technologies and industry best practices, we create customized solutions that drive business growth.",
    "We're a client-centric organization that prioritizes communication, collaboration, and delivering exceptional value throughout the software development journey."
  ]

  return (
    <div id="about" style={{ backgroundColor: "#F3F8FD" }}>
      <h2
        className="mb-8 mt-0 text-4xl pt-24 tracking-tight font-bold text-center text-gray-900 light:text-white"
        style={{ color: "#035898", 'fontFamily':'poppins', fontWeight: '600' }}
      >
        What we do
      </h2>

      <div className={styles.mission}>
        <div className={styles['mission-content']}>
          <br />
          <div ref={ref} className={styles['points-container']}>
            {points.map((point, index) => (
              <div className={`${styles['bullet-point']} ${(inView)?(styles['bullet-point-animation']):('')} `}>
                <div className={styles['bullet-div']}>
                  <IoIosArrowDroprightCircle size={28} color="#035898" />
                </div>
                <p>
                  {point}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles['mission-image']}></div>
      </div>
    </div>
  );
}
