

function CustomeBt(props) {
    return (
        <a href={props.link}>
            <div style={
                {
                    'background-color': (props.bg) ? (props.bg) : ('#ffffff'),
                    'height': (props.height) ? (props.height) : ('40px'),
                    'width': (props.width) ? (props.width) : ('160px'),
                    'border-radius': (props.borderRadius) ? (props.borderRadius) : ('5px'),
                    'border': '1px solid ',
                    'border-color': (props.bg) ? (props.bg) : ('#ffffff'),
                    'borderColor': (props.borderColor) ? (props.borderColor) : ('none'),
                    'line-height': (props.height) ? (props.height) : ('35px'),
                    'text-align': 'center',
                }
            }>
                <span style={
                    {
                        'display': 'inline-block',
                        'vertical-align': 'middle',
                        'line-height': 'normal',
                        'color': (props.color) ? (props.color) : ('white'),
                        'fontFamily': 'poppins',
                        'fontWeight': (props.fontWeight) ? (props.fontWeight) : ('900'),
                        'fontSize': (props.fontSize) ? (props.fontSize) : ('12px')
                    }
                }>
                    {(props.content) ? (props.content) : ('Custom Button')}
                </span>

            </div>
        </a>
    )
}

export default CustomeBt;