import StarryNight from "./StarryNight";
import styles from "../css/StarryNight.module.css";
import React from "react";

function Introduction() {
  return (
    <div className={styles.infoContainer}>
      <div className="backcanvas"><StarryNight>
      </StarryNight></div>
      <div className={styles.infoContent}>
        <div className={styles.content}>
          <h1>Innovate and Thrive</h1>
          <h1>with Asteramind</h1>
          <p>
          Asteramind is a leading software company specializing in web development, app development, game development and UI/UX design. With a passion for digital excellence, our expert team crafts innovative solutions that elevate businesses to new heights. Join us on a journey of unrivaled creativity and technological prowess.
          </p>

        </div>
        <div className={styles.image}>
        </div>
      </div>
    </div>
  );
}

export default Introduction;