import "../css/InfiniteScrollAnimation.css";
import HTML5 from "images/HTML.svg";
import CSS from "images/CSS.svg";
import JS from "images/JS.svg";
import NODEJS from "images/NODEJS.svg";
import REACT from "images/REACT.svg";
import ANGULAR from "images/ANGULAR.svg";
import BOOTSTRAP from "images/BOOTSTRAP.svg";
import TAILWIND from "images/TAILWIND.svg";
import PYTHON from "images/PYTHON.svg";
import SQL from "images/SQL.svg";
import MONGODB from "images/MONGODB.svg";
import NEXT from "images/NEXT.svg";
import PHP from "images/PHP.svg";
import CPLUS from "images/CPLUS.svg";
import C from "images/C.svg";
import GIT from "images/GIT.svg";
import FLUTTER from "images/FLUTTER.svg";

export default function InfiniteScrollAnimation() {
  return (
    <div className="section-header">
      <h2
        className="mb-8 mt-0 text-4xl pt-24 tracking-tight font-bold text-center text-gray-900 light:text-white"
        style={{ color: "#035898", 'fontFamily':'poppins', fontWeight: '600' }}
      >
        Technologies we work with
      </h2>
      <div className="container">
        <div className="scroll-parent">
          <div className="scroll-element primary">
            <img src={HTML5} alt="HTML Logo" />
            <img src={CSS} alt="CSS Logo" />
            <img src={JS} alt="JS Logo" />
            <img src={NODEJS} alt="NODEJS Logo" />
            <img src={REACT} alt="REACT Logo" />
            <img src={ANGULAR} alt="ANGULAR Logo" />
            <img src={BOOTSTRAP} alt="BOOTSTRAP Logo" />
            <img src={TAILWIND} alt="TAILWIND Logo" />
            <img src={PYTHON} alt="PYTHON Logo" />
            <img src={SQL} alt="SQL Logo" />
            <img src={MONGODB} alt="MONGODB Logo" />
            <img src={NEXT} alt="NEXTJS Logo" />
            <img src={PHP} alt="PHP Logo" />
            <img src={C} alt="C Logo" />
            <img src={CPLUS} alt="CPLUS Logo" />
            <img src={GIT} alt="GIT Logo" />
            <img src={FLUTTER} alt="FLUTTER Logo" />
          </div>
          <div className="scroll-element secondary">
            <img src={HTML5} alt="HTML Logo" />
            <img src={CSS} alt="CSS Logo" />
            <img src={JS} alt="JS Logo" />
            <img src={NODEJS} alt="NODEJS Logo" />
            <img src={REACT} alt="REACT Logo" />
            <img src={ANGULAR} alt="ANGULAR Logo" />
            <img src={BOOTSTRAP} alt="BOOTSTRAP Logo" />
            <img src={TAILWIND} alt="TAILWIND Logo" />
            <img src={PYTHON} alt="PYTHON Logo" />
            <img src={SQL} alt="SQL Logo" />
            <img src={MONGODB} alt="MONGODB Logo" />
            <img src={NEXT} alt="NEXTJS Logo" />
            <img src={PHP} alt="PHP Logo" />
            <img src={C} alt="C Logo" />
            <img src={CPLUS} alt="CPLUS Logo" />
            <img src={GIT} alt="GIT Logo" />
            <img src={FLUTTER} alt="FLUTTER Logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
