import styles from "../css/CareerLanding.module.css";
import React from "react";

export default function CareerLanding() {
    return (
        <div className={styles.infoContainer}>
            <div className={styles.infoContent}>
                <div className={styles.image}>
                </div>
                <div className={styles.content}>
                          <h2
        className="mb-2 mt-6 sm:mt-12 text-4xl tracking-tight font-bold light:text-white"
        style={{ color: "#035898", 'fontFamily':'poppins', fontWeight: '600' }}>Join a company that values raw talent</h2>
                    <p style={{fontSize:'14px'}}>
                    At Asteramind, we're more than just a company. We're a community of passionate individuals who share a common vision: to innovate and make a difference in the world. We believe that everyone has the potential to achieve great things, and we're committed to providing our team members with the support and resources they need to excel. By joining Asteramind, you'll be part of a dynamic and collaborative team that's always looking for ways to push the boundaries of what's possible. Together, we'll work towards creating innovative solutions that have a real impact on people's lives.
                    </p>
                </div>

            </div>
        </div>
    );
}
