import Layout from './Layout';
import Introduction from '../components/Introduction';
import Testimonals from '../components/testimonals';
import ContactUs from '../components/contactus';
import FAQS from '../components/FAQ';
import Mission from '../components/Mission';
import ServiceCards from '../components/Services'
import InfiniteScrollAnimation from '../components/InfiniteScrollAnimation';

function HomePage() {
  return (
    <Layout>
      <Introduction></Introduction>
      <Mission></Mission>
      <ServiceCards></ServiceCards>
      <InfiniteScrollAnimation></InfiniteScrollAnimation>
      <FAQS></FAQS>
      <Testimonals></Testimonals>
      <ContactUs></ContactUs>
    </Layout>
  );
}

export default HomePage;
